@import "uikit.min.css";

@charset "utf-8";

$doc-font-size: 12 !default;

/* system typefaces */
$serif: Georgia, Times, serif !default;
$sans-serif: 'Lato', -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
  "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;
$monospace: 'Cascadia Code', Monaco, Consolas, "Lucida Console", monospace !default;

/* sans serif typefaces */
$sans-serif-narrow: $sans-serif !default;
$helvetica: Helvetica, "Helvetica Neue", Arial, sans-serif !default;

/* serif typefaces */
$georgia: Georgia, serif !default;
$times: Times, serif !default;
$bodoni: "Bodoni MT", serif !default;
$calisto: "Calisto MT", serif !default;
$garamond: Garamond, serif !default;

$global-font-family: $sans-serif !default;
$header-font-family: $sans-serif !default;
$caption-font-family: $serif !default;

/* type scale */
$type-size-1: 2.441em !default; // ~39.056px
$type-size-2: 1.953em !default; // ~31.248px
$type-size-3: 1.563em !default; // ~25.008px
$type-size-4: 1.35em !default; // ~20px
$type-size-5: 1.25em !default; // ~16px
$type-size-6: 1.0em !default; // ~12px
$type-size-7: 0.75em !default; // ~11px
$type-size-8: 0.685em !default; // ~10px

$small: 600px !default;
$medium: 768px !default;
$medium-wide: 900px !default;
$large: 1024px !default;
$x-large: 1360px !default;
$max-width: $x-large !default;

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials

.responsive-wrap iframe{ max-width: 100%;}

.author__avatar img {
      border-radius: 5%;
}

.author__urls a {
    border-bottom-color: rgb(227, 44, 25);
    border-bottom-width: 2px;
    border-bottom-style: solid;
    text-decoration-line: none;

    &:hover {
      text-decoration: none;
   }
}

.page__content a {
    border-bottom-color: rgb(227, 44, 25);
    border-bottom-width: 2px;
    border-bottom-style: solid;
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
   }
}

.page__content code {
    padding-top: 0.01rem;
    padding-bottom: 0.00rem;
}

html {
    font-size: 14px; // originally 16px
    @include breakpoint($medium) {
        font-size: 16px; // originally 18px
    }

    @include breakpoint($large) {
        font-size: 18px; // originally 20px
    }

    @include breakpoint($x-large) {
        font-size: 20px; // originally 22px
    }
}

small,
.small {
  font-size: 80%;
}

// Up to 2.1
@media (max-aspect-ratio: 1920/914) {
  .stickemup {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgba(238, 238, 238, 1);
    z-index: 666;
  }
}

.top-scroll-progress-bar {
  background: linear-gradient(to right,rgb(227, 44, 25), var(--scroll), transparent 0);
  background-repeat: no-repeat;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 4px;
  z-index: 1;
}

// Up to 2.1
@media (max-aspect-ratio: 1920/914) {
  .benchmark-chart-container {
    height: 25em;
    position: relative; 
  }
}

@media (min-aspect-ratio: 1920/914) {
  .benchmark-chart-container {
    height: 20em;
    position: relative; 
  }
}

pre.highlight {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
}

ul.uk-tab > li {
  margin-bottom: 0 !important
}

.helpbutton {
  padding: 0;
  border: none;
  background: none;
  cursor: help;
  position: absolute;
  left: -3%;
  top: 5%;
  z-index: 666;
}
div.rotated-header-container {
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
}
div.rotated-header {
  transform: 
  // Magic Numbers
  translate(0px, 0px)
  // 45 is really 360-45
  rotate(305deg);
  //width: 30px;
  padding-bottom: 10px;
}

.bootstrap-table .fixed-table-container .table thead th .th-inner {
  padding-bottom: .25rem !important;
  padding-top: .25rem !important;
}

.bootstrap-table .fixed-table-container .table thead th .sortable {
  padding-right: 22px !important;
}

  object.animated-border{
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:100%;
    min-height:100%;
    margin-bottom:3rem;
    align-self:center;
    background:transparent;
    padding:0.5rem 0.5rem;
    margin:0 0.5rem;
    transition:all .3s ease;
    letter-spacing:1px;
    outline:none;

    border:dotted 2px #41403E;

    
    box-shadow: 10px 19px 17px -13px hsla(0,0%,0%,.2);
    border-radius: 10px 25px;
    &:hover{
       box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
    } 
}

.pull-right.pagination {
  padding: 0
}

.pull-right.pagination>ul>li {
  padding: 0;
  line-height: 0;
}


.pull-right.pagination>ul {
  line-height: 0
}

.pull-left.pagination-detail {
  display: none;
  visibility: hidden;
}
.divTable{
	display: table;
	width: 100%;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	display: table-header-group;
}
.divTableCell, .divTableHead {
	border: none;
	display: table-cell;
	padding: 3px 10px;
}
.divTableHeading {
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}
